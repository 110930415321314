import { ContractorPayment, schemes, zSchema } from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";

export const deleteContractorPaymentSchema = zSchema.object({
  contractorPaymentID: schemes.mongoObjectId(),
});

export type TDeleteContractorPaymentRequest = zSchema.infer<
  typeof deleteContractorPaymentSchema
>;

export const deleteContractorPayment = (axios: IAxiosInstance) => {
  return async (requestBody: TDeleteContractorPaymentRequest) => {
    const response = await axios.noRetry.post<IApiResponse<ContractorPayment>>(
      "/api/deletePayment",
      requestBody
    );

    response.data.data;
  };
};
