import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TUpdateChargePolicyRequestSchema,
  TUpdateChargePolicyResponseSchema,
} from "./updateChargePolicy.schema";

export const updateChargePolicy = (axios: IAxiosInstance) => {
  return async (body: TUpdateChargePolicyRequestSchema) => {
    const { policy_id, ...rest } = body;

    const response = await axios.noRetry.patch<
      IApiResponse<TUpdateChargePolicyResponseSchema>
    >(`/api/charges-and-fees/policies/${policy_id}`, rest);

    return response.data;
  };
};
