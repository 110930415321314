import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TCreatePushToCardWorkerRequestSchema,
  TCreatePushToCardWorkerResponseSchema,
} from "./createPushToCardWorker.schema";

export const createPushToCardWorker = (axios: IAxiosInstance) => {
  return async (body: TCreatePushToCardWorkerRequestSchema) => {
    const response = await axios.noRetry.post<
      IApiResponse<TCreatePushToCardWorkerResponseSchema>
    >(`/api/push-to-card/worker`, body);

    return response;
  };
};
