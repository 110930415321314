export const veriffDecisionRiskLabelMap: Record<
  string,
  { label: string; description: string }
> = {
  dob_differences_detected: {
    label: "DOB differences detected",
    description:
      "Date of birth sent by the client or extracted from previous session attempts is different from the date of birth extracted from the document.",
  },
  session_vendor_provided_name_not_matching_with_name_on_the_document: {
    label:
      "Session vendor provided name not matching with name on the document",
    description:
      "Name sent by the client is not exactly the same to the name extracted from the document.",
  },
  session_vendor_provided_name_not_similar_with_name_on_the_document: {
    label: "Session vendor provided name not similar with name on the document",
    description:
      "Name sent by the client is not similar (less than 60% overlap) to the name we extracted from the document.",
  },
  low_face_similarity_poor_document_image_sharpness: {
    label: "Low face similarity - Poor document image sharpness",
    description:
      "Face on the selfie doesn't match the face on the document due to poor document image sharpness",
  },
  low_face_similarity_poor_unknown_document_image_conditions: {
    label: "Low face similarity - Poor / Unknown document image conditions",
    description:
      "Face on the selfie doesn't match the face on the document due to poor document image conditions",
  },
  low_face_similarity_document_face_not_clearly_visible: {
    label: "Low face similarity - Document face not clearly visible",
    description:
      "Face on the selfie doesn't match the face on the document due to face on the document or portrait not clearly visible",
  },
  low_face_similarity_image_quality_good: {
    label: "Low face similarity - Image quality good",
    description:
      "Face on the selfie doesn't match the face on the document and document image is good quality",
  },
  possible_age_risk_young: {
    label: "Possible age risk - YOUNG",
    description: "According to data extraction, person is under 18 years old.",
  },
  possible_age_risk_old: {
    label: "Possible age risk - OLD",
    description: "According to data extraction, person is over 65 years old.",
  },
  person_previously_approved: {
    label: "Person previously approved",
    description:
      "Person in the session has been previously approved in current integration.",
  },
  session_face_is_related_with_recent_fraud_or_tampering: {
    label: "Session face is related with recent fraud or tampering",
    description:
      "Person in the session has been previously declined with “Known fraud” or “Suspected document tampering“ in the last 48 hours in current integration.",
  },
  potential_document_user_age_mismatch: {
    label: "Potential document user age mismatch",
    description:
      "Age of the person extracted from the document is not in the age range predicted by facial recognition service. This check identifies the potential of a fraudulent user verifying with documents from someone whose age is significantly different.",
  },
  session_person_crosslinked_with_multiple_dobs: {
    label: "Session person cross-linked with multiple DOBs",
    description:
      "In the crosslinks or session attempts the user’s face is connected with different dates of birth.",
  },
  session_person_crosslinked_with_multiple_documents: {
    label: "Session person cross-linked with multiple documents",
    description:
      "In the crosslinks or session attempts the user's face is connected with different documents.",
  },
  session_person_crosslinked_with_multiple_names: {
    label: "Session person cross-linked with multiple names",
    description:
      "In the crosslinks or session attempts the user's face is connected with different names.",
  },
  document_previously_approved: {
    label: "Document previously approved",
    description:
      "Document in the session has been previously approved in current integration.",
  },
  document_integration_level_crosslinked_with_multiple_vendor_sent_names: {
    label:
      "Document integration level cross-linked with multiple vendor sent names",
    description:
      "Document in the session is associated with different names sent by the client.",
  },
  document_integration_level_crosslinked_with_multiple_vendor_sent_dobs: {
    label:
      "Document integration level cross-linked with multiple vendor sent DOBs",
    description:
      "Document in the session is associated with different DOBs sent by the client.",
  },
  document_integration_level_crosslinked_with_multiple_approves: {
    label: "Document integration level cross-linked with multiple approves",
    description:
      "Document in the session has been previously approved multiple times in current integration.",
  },
  document_integration_level_crosslinked_with_suspicious_behaviour: {
    label: "Document integration level cross-linked with suspicious behaviour",
    description:
      "Document in the session has been previously declined for suspicious behaviour in current integration.",
  },
  document_integration_level_crosslinked_with_multiple_declines: {
    label: "Document integration level cross-linked with multiple declines",
    description:
      "Document in the session has been previously declined multiple times in current integration.",
  },
  document_integration_level_crosslinked_with_fraud: {
    label: "Document integration level cross-linked with fraud",
    description:
      "Document in the session has been previously declined for known fraud in current integration.",
  },
  document_integration_level_crosslinked_with_tampering: {
    label: "Document integration level cross-linked with tampering",
    description:
      "Document in the session has been previously declined for suspected document tampering in current integration.",
  },
  document_holder_photo_crosslinked_with_multiple_sessions: {
    label: "Document holder photo cross-linked with multiple sessions",
    description:
      "Document holder photo is linked to a previously submitted session in current integration.",
  },
  document_holder_photo_crosslinked_with_multiple_dobs: {
    label: "Document holder photo cross-linked with multiple DOBs",
    description:
      "In the crosslinks or session attempts the document holder photo is connected with different dates of birth.",
  },
  document_holder_photo_crosslinked_with_multiple_documents: {
    label: "Document holder photo cross-linked with multiple documents",
    description:
      "In the crosslinks or session attempts the document holder photo is connected with different documents.",
  },
  network_and_device_timezone_mismatch: {
    label: "Network and device timezone mismatch",
    description:
      "Device timezone is different from the connected network's timezone.",
  },
  network_and_carrier_country_mismatch: {
    label: "Network and carrier country mismatch",
    description:
      "SIM card origin country is different from  the connected network country.",
  },
  network_and_browser_timezone_mismatch: {
    label: "Network and browser timezone mismatch",
    description:
      "Network timezone is different from the used web browser's timezone.",
  },
  browser_incognito_mode_detected: {
    label: "Browser incognito mode detected",
    description:
      "Private browsing mode has been enabled during the verification flow.",
  },
  sdk_emulator_detected: {
    label: "SDK emulator detected",
    description: "Mobile flow was emulated on a computer.",
  },
  jailbroken_device_detected: {
    label: "Jailbroken device detected",
    description:
      "A jailbroken or rooted device was used to access the verification flow.",
  },
  session_traffic_proxied: {
    label: "Session traffic proxied",
    description:
      "Network traffic was proxied during verification flow. This risk takes into account multiple inputs including anonymizer detection / VPN usage and device / network timezone differences.",
  },
  data_center_usage_detected: {
    label: "Data center usage detected",
    description: "Network routed through data center during verification flow.",
  },
  traffic_anonymizer_detected: {
    label: "Traffic anonymizer detected",
    description: "Traffic is coming from a known anonymizer proxy.",
  },
  session_accessed_from_multiple_countries: {
    label: "Session accessed from multiple countries",
    description:
      "Verification session flow was accessed from IP's belonging to different countries.",
  },
  session_missing_device_fingerprinting_information: {
    label: "Session missing technical information",
    description:
      "No technical information available about the device or the network.",
  },
  device_previously_approved: {
    label: "Device previously approved",
    description: "Same device was used in another session and was approved.",
  },
  document_and_network_country_mismatch: {
    label: "Network and document country mismatch",
    description:
      "Document country origin is different from  the connected network country.",
  },
  document_and_device_country_mismatch: {
    label: "Document and device country mismatch",
    description:
      "Document country origin is different from the device region selection.",
  },
  session_ip_related_to_multiple_sessions: {
    label: "Session IP related to multiple sessions",
    description:
      "Session IP is related to five or more sessions originating from the same IP address, with at least 60% of the sessions having conclusive decisions (Approved or Declined) in the last 14 days.",
  },
  location_restricted_ip: {
    label: "Location restricted: IP",
    description:
      "Session IP is from select Cities in Donetsk or Luhansk that are under Separatist control as well as traffic from Crimea.",
  },
  document_restricted_issuing_authority_crimea: {
    label: "Document restricted: issuing authority Crimea",
    description:
      "Session has Russian documents (Passport and Driving Lisence) that were issued in Crimea.",
  },
  session_crosslinked_with_damaged_document: {
    label: "Session cross-linked with damaged document",
    description:
      "Current session is linked to an earlier session where a damaged document was shown.",
  },
  session_crosslinked_with_known_fraud: {
    label: "Session cross-linked with known fraud",
    description:
      "Current session is linked to an earlier session that was declined with Known Fraud.",
  },
  session_crosslinked_with_person_and_document_photo_mismatch: {
    label: "Session cross-linked with person and document photo mismatch",
    description:
      "Current session is linked to an earlier session where the document photo didn't match with the person showing it.",
  },
  session_crosslinked_with_suspected_document_tampering: {
    label: "Session cross-linked with suspected document tampering",
    description:
      "Current session is linked to an earlier session that was declined due to document tampering.",
  },
  session_crosslinked_with_suspicious_behaviour: {
    label: "Session cross-linked with suspicious behaviour",
    description:
      "Current session is linked to an earlier session that was declined due to photos being shown from screen or a printout.",
  },
  session_crosslinked_with_velocity_abuse: {
    label: "Session cross-linked with velocity/abuse",
    description:
      "Current session is linked to an earlier session that was declined due to velocity abuse.",
  },
  session_face_related_to_a_session_with_potentially_different_data: {
    label: "Session face related to a session with potentially different data",
    description:
      "Current session's user's face is linked to a previous session with the same user, and personal/document data may differ.",
  },
  session_crosslinked_across_multiple_clients: {
    label: "Session crosslinked across multiple clients",
    description:
      "Current session is linked with a previously declined session for another client in the same industry.",
  },
  session_face_crosslinked_with_multiple_sessions: {
    label: "Session face cross-linked with multiple sessions",
    description:
      "Current session's user's face is linked to a previously submitted session in current integration.",
  },
};
