import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TGetChargePolicyRequestSchema,
  TGetChargePolicyResponseSchema,
} from "./getChargePolicy.schema";

export const getChargePolicy = (axios: IAxiosInstance) => {
  return async (params: TGetChargePolicyRequestSchema) => {
    const { odo_user_id, ...rest } = params;

    const response = await axios.noRetry.get<
      IApiResponse<TGetChargePolicyResponseSchema>
    >(`/api/charges-and-fees/${odo_user_id}/policies`, { params: rest });

    return response.data;
  };
};
