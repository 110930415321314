import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";
import {
  TCreateChargePolicyRequestSchema,
  TCreateChargePolicyResponseSchema,
} from "./createChargePolicy.schema";

export const createChargePolicy = (axios: IAxiosInstance) => {
  return async (body: TCreateChargePolicyRequestSchema) => {
    const response = await axios.noRetry.post<
      IApiResponse<TCreateChargePolicyResponseSchema>
    >(`/api/charges-and-fees/policies`, body);

    return response.data;
  };
};
