import {
  IChargesAndFeesPolicy,
  PricingRule,
  schemes,
  zSchema,
} from "@zeal/common";

export const updateChargePolicyRequestSchema = zSchema.object({
  odo_user_id: schemes.mongoOrUuid(),
  policy_id: schemes.uuid(),
  pricing: zSchema.object({
    pricing_id: schemes.uuid(),
    amount: zSchema.number().optional(),
    percentage: zSchema.number().optional(),
    rule: zSchema.nativeEnum(PricingRule).optional(),
    max_cap: zSchema.number().optional(),
  }),
});

export type TUpdateChargePolicyRequestSchema = zSchema.infer<
  typeof updateChargePolicyRequestSchema
>;

export type TUpdateChargePolicyResponseSchema = IChargesAndFeesPolicy;
