import {
  IChargesAndFeesPolicy,
  PolicyStatus,
  schemes,
  zSchema,
} from "@zeal/common";

export const getChargePolicyRequestSchema = zSchema.object({
  odo_user_id: schemes.mongoOrUuid(),
  policy_id: schemes.uuid().optional(),
  status: zSchema.nativeEnum(PolicyStatus).optional(),
});

export type TGetChargePolicyRequestSchema = zSchema.infer<
  typeof getChargePolicyRequestSchema
>;

export type TGetChargePolicyResponseSchema = IChargesAndFeesPolicy[];
