import { z } from "zod";
import { RouteMap } from "./customerReporting";

export const i9EVerifySummaryFieldsSchema = z.object({
  default_wage: z.boolean().optional(),
});

export type I9EVerifySummaryFields = z.infer<
  typeof i9EVerifySummaryFieldsSchema
>;

export const i9EVerifySummarySchema = z.object({
  companyID: z.string(),
  active_workers: z.boolean().optional().default(true),
  start_date: z.string(),
  end_date: z.string(),
  limit: z.number().optional(),
  cursor: z.string().nullable(),
});

export type I9EVerifySummaryRequestParams = z.infer<
  typeof i9EVerifySummarySchema
> & {
  has_live_key: boolean;
};

export const i9EVerifyCountSchema = i9EVerifySummarySchema.pick({
  companyID: true,
  active_workers: true,
});

export const createI9EVerifySummarySchema = i9EVerifySummarySchema.omit({
  cursor: true,
});

const i9EVerifySchema = z.object({
  employee_id: z.string(),
  external_id: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  working_state: z.string(),
  residency_state: z.string(),
  start_date: z.string(),
  employee_status: z.string(),
  i9_started_at: z.string().optional(),
  i9_status: z.string().optional(),
  i9_status_achieved_on: z.string().optional(),
  admin_review_status: z.string().optional(),
  admin_reviewed_on: z.string().optional(),
  admin_reviewed_by: z.string().optional(),
  everify_status: z.string().optional(),
  everify_status_updated_at: z.string().optional(),
  list_a_document: z.string().optional(),
  list_a_document_status: z.string().optional(),
  list_a_document_flags: z.string().optional(),
  list_b_document: z.string().optional(),
  list_b_document_status: z.string().optional(),
  list_b_document_flags: z.string().optional(),
  list_c_document: z.string().optional(),
  list_c_document_status: z.string().optional(),
  list_c_document_flags: z.string().optional(),
});

export type I9EVerify = z.infer<typeof i9EVerifySchema>;

export type I9EVerifySummaryResponse = {
  kind: RouteMap.i9_everify_summary;
  workers: I9EVerify[];
};

export const i9EVerifySummaryWorkerTypeSchema = z.object({
  data: z.object({
    workers: z.array(i9EVerifySchema),
  }),
});
