import {
  IChargesAndFeesPolicy,
  PolicyPayer,
  PolicyType,
  PricingRule,
  TransferType,
  schemes,
  zSchema,
} from "@zeal/common";

export const createChargePolicyRequestSchema = zSchema.object({
  odo_user_id: schemes.mongoOrUuid(),
  paid_by: zSchema.nativeEnum(PolicyPayer),
  policy_type: zSchema.nativeEnum(PolicyType),
  transfer_type: zSchema.nativeEnum(TransferType),
  pricing: zSchema.object({
    amount: zSchema.number(),
    percentage: zSchema.number(),
    rule: zSchema.nativeEnum(PricingRule),
    max_cap: zSchema.number(),
  }),
});

export type TCreateChargePolicyRequestSchema = zSchema.infer<
  typeof createChargePolicyRequestSchema
>;

export type TCreateChargePolicyResponseSchema = IChargesAndFeesPolicy;
