import {
  ContractorPayment,
  createContractorPaymentSchema,
  zSchema,
} from "@zeal/common";
import { IAxiosInstance } from "../../../AbstractApiClient/AbstractApiClient";
import { IApiResponse } from "../../../AbstractApiClient/IApiResponse";

const updatePaymentSchema = createContractorPaymentSchema
  .pick({
    companyID: true,
    amount: true,
    disbursement: true,
    approval_required: true,
    pay_date: true,
    type: true,
    speed: true,
  })
  .partial()
  .extend({
    contractorPaymentID: zSchema.string(),
    customerAccountID: zSchema.string().optional(),
    approved: zSchema.boolean().optional(),
  });
export type TUpdateContractorPaymentRequest = zSchema.infer<
  typeof updatePaymentSchema
>;

export const updateContractorPayment = (axios: IAxiosInstance) => {
  return async (requestBody: TUpdateContractorPaymentRequest) => {
    const response = await axios.noRetry.patch<IApiResponse<ContractorPayment>>(
      "/contractorPayment",
      requestBody
    );
    const resData = response.data;

    if (resData.success) {
      return resData.data;
    }

    throw new Error("Something went wrong");
  };
};
