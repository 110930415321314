import {
  allKeysOptionalI9DocumentZodSchema,
  EAuthorizedRepresentativeDecision,
  ECitizenshipStatus,
  EDocumentField,
  EDocumentKey,
  EDocumentList,
  EI9ChildStep,
  EI9DocumentPhotoSide,
  EI9OnboardingStep,
  schemes,
  veriffIDVDecisionResponseSchema,
} from "@zeal/common";
import { z } from "zod";

const EI9StepUnion = { ...EI9OnboardingStep, ...EI9ChildStep };

export const updateI9OnboardingInfoRequestSchema = z.object({
  /**
   * companyID and workerID are extracted from the context.
   * Since this zod schema is consumed by the morn action, we don't need to validate these
   * fields in the request body.
   * The handler will validate these fields.
   */
  companyID: schemes.companyID().optional(),
  workerID: schemes.mongoObjectId().optional(),
  last_completed_step: z.nativeEnum(EI9StepUnion),
  signature: z.string().optional(),
  personal_info: z
    .object({
      otherLastName: z.string().optional(),
      includeEmailOnI9: z.boolean(),
      includePhoneOnI9: z.boolean(),
    })
    .optional(),
  authorized_representative: z
    .object({
      id: schemes.uuid().optional(),
      first_name: z.string().optional(),
      last_name: z.string().optional(),
      email: schemes.email().optional(),
      phone: z.string().length(10).optional(),
      decision: z.nativeEnum(EAuthorizedRepresentativeDecision).optional(),
      decision_made_on: z.string().optional(),
      requested_changes: z.array(z.string()).optional(),
      signature: z.string().optional(),
      worker_first_name: z.string().optional(),
      worker_last_name: z.string().optional(),
    })
    .optional(),
  citizenship_info: z
    .object({
      status: z.nativeEnum(ECitizenshipStatus),
      alien_number: z
        .string()
        .regex(
          /^A\d{8,9}$/,
          'Invalid alien number. Must be the letter "A" followed by 8 or 9 digits.'
        )
        .optional(),
      i94_number: z
        .string()
        .regex(/^[0-9A-Za-z]{1,11}$/, "Invalid I94 number.")
        .optional(),
      i551_number: z
        .string()
        .regex(/^[A-Za-z]{3}(\d{10}|[*]\d{9})$/, "Invalid I551 number.")
        .optional(),
      foreign_passport_number: z
        .string()
        .regex(/^[A-Za-z0-9]{1,12}$/, "Invalid foreign passport number.")
        .optional(),
      alien_number_or_i94_number_or_foreign_passport_number: z
        .enum(
          [
            EDocumentField.ALIEN_NUMBER,
            EDocumentField.I94_NUMBER,
            EDocumentField.FOREIGN_PASSPORT_NUMBER,
          ],
          {
            required_error:
              "Either alien number, I94 number, or foreign passport number is required.",
          }
        )
        .optional(),
      country_code: z
        .string({
          required_error:
            "Please select the country that issued your foreign passport.",
        })
        .optional(),
      expiration_date: z.string().optional(),
    })
    .optional(),
  documents: z
    .array(
      z.object({
        document_key: z.nativeEnum(EDocumentKey),
        document_list: z.nativeEnum(EDocumentList),
        document_data: allKeysOptionalI9DocumentZodSchema.optional(),
        veriff_id: z.string().optional(),
        veriff_decision: veriffIDVDecisionResponseSchema.optional(),
        document_photos: z
          .array(
            z.object({
              doc_base64: z.string().min(1).optional(),
              doc_url: z.string().min(1).optional(),
              description: z.string().optional(),
              side: z.nativeEnum(EI9DocumentPhotoSide).optional(),
              file_suffix: z.number().optional(),
            })
          )
          .optional(),
      })
    )
    .optional(),
});

export type TUpdateI9OnboardingInfoRequest = z.infer<
  typeof updateI9OnboardingInfoRequestSchema
>;
